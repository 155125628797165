import React from "react";
import Header from "./Header";
import google_pay_icon from "../assets/images/svg/google_pay_icon.svg";
import user_icon from "../assets/images/svg/users_icon.svg";
import app_stor_icon from "../assets/images/svg/app_staor_icon.svg";
import mobile_img from "../assets/images/png/mobile_image.png";

const Hero = () => {
  return (
    <div className="bg_red min-vh-100 d-flex flex-column ">
      <div className="bg-white">
        <Header />
      </div>
      <div className="container py-4 d-flex flex-column justify-content-center flex-grow-1">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <h1 className="mb-0 text-center text-md-start ff fs_80 fw-bolder text-white">
              Your News, Your Voice, Your Platform
            </h1>
            <p className="text-white pt-2 text-center text-md-start ff fs_20">
              Let’s Download AppDates
            </p>
            <div className="d-sm-flex text-center mt-md-4 justify-content-center justify-content-md-start align-items-center gap-md-4 gap-2">
              <a
                target="_blanck"
                href="https://play.google.com/store/apps/details?id=com.appdates.android"
              >
                <img
                  className="mt-3 custom_width mt-md-0"
                  src={google_pay_icon}
                  alt="google_pay_icon"
                />
              </a>
              <a
                className=""
                target="_blanck"
                href="https://apps.apple.com/us/app/appdates/id6511220935"
              >
                <img
                  className="mt-3 mt-md-0 custom_width"
                  src={app_stor_icon}
                  alt="app_stor_icon"
                />
              </a>
            </div>
            {/* <img className="mt-5" src={user_icon} alt="" /> */}
          </div>
          <div className="col-md-5 mt-5 mt-md-0">
            <img className="w-100" src={mobile_img} alt="mobile_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React, { useState } from "react";
import logo from "../assets/images/svg/web_logo.svg";
import cross_icon from "../assets/images/svg/cross_icon.jpg";

const Header = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className="py-3">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <a href="#">
            <img width={150} src={logo} alt="web logo" />
          </a>
          {/* <ul className="mb-0 ps-0 d-sm-flex d-none align-items-center gap-lg-5 gap-3">
            <li>
              <a className="fs_18 ff text-white" href="#">
                About
              </a>
            </li>
            <li>
              <a className="fs_18 ff text-white" href="#">
                Contact
              </a>
            </li>
            <button className="py-2 rounded-2 px-4 ff text-black bg-white  border-0">
              Download Now
            </button>
          </ul> */}
          {/* <div
            className={`navmanu d-sm-none position-relative custom_zindex d-block burger  ${
              sidebarVisible ? "active" : ""
            }`}
            onClick={toggleSidebar}
          >
            <div></div>
            <div className=""></div>
            <div></div>
          </div> */}
        </div>
      </div>
      {/* {sidebarVisible && (
        <div
          className={`sidebar d-flex flex-column justify-content-center align-items-center  ${
            sidebarVisible ? "show " : "hide"
          }`}
        >
          <img
            onClick={toggleSidebar}
            className=" cursor_pointer position-absolute cross_icon d-sm-block d-none"
            width={30}
            src={cross_icon}
            alt="cross_icon"
          />
          <div className="d-block align-items-center gap-5 ">
            <ul className=" mb-0 ps-0 text-center">
              <li>
                <a className="fs_18 ff text-white" href="#">
                  About
                </a>
              </li>
              <li className="mt-3">
                <a className="fs_18 ff text-white" href="#">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Header;
